
<template>
    <div class="layoutA">
        <img
            src="../assets/overseas.png"
            mode="scaleToFill" style="width:100%;"/>
    </div>

    <div class="bottom">
        <b>© 广州市越秀区紫腾教育培训中心有限公司 版权所有。</b>
        <a class="beian"
            href="https://beian.miit.gov.cn"  rel="nofollow">粤ICP备2021074338号</a>
        <!-- | 本公司荣获SGS的ISO9001:2008认证 |
        增值电信业务经营许可证：粤B2-20170231 Copyright (C) 2002-2018 |
        <a class="beian" href="https://gdca.miit.gov.cn/">广东省通信管理局</a> -->
        </div>

        <div class="backFast" v-if="roll === 1">
            <div class="ivu-back-top ivu-back-top-show" data-v-47323bf2="" style="bottom: 500px; right: 30px;">
                
                <div class="customerSys bak customerSysWm" data-v-47323bf2="" @click="openWechat">
                    <img src="../assets/customerSys.png">
                </div>
                <div class="weixin_wm">
                    <img src="../assets/weixin_wm.png"/>
                </div>
            </div>
            <div class="ivu-back-top ivu-back-top-show" data-v-47323bf2="" style="bottom: 420px; right: 30px;">
                <div class="followUs bak" data-v-47323bf2="">
                    <img src="../assets/followUs.png">
                </div>
                <div class="erweima_wm">
                    <!-- <img src="../assets/erweima_wm.png"/> -->
                    <img src="../assets/zbzt-douyin.png"/>
                    抖音号
                    <img src="../assets/zbzt-vlog.png"/>
                    视频号
                    <img src="../assets/zbzt-wechat.jpg"/>
                    公众号
                </div>
                
            </div>
            <div class="ivu-back-top ivu-back-top-show" data-v-47323bf2="" style="bottom: 340px; right: 30px;">
                <div class="enrollNow bak" data-v-47323bf2="" @click="openEnrollNow">
                    <img src="../assets/enrollNow.png">
                </div>
            </div>
            <!-- <div class="ivu-back-top ivu-back-top-show" data-v-47323bf2="" style="bottom: 260px; right: 30px;"> -->
                <BackTop :height="100" :bottom="260">
                    <div class="backTop bak" data-v-47323bf2="">
                        <img src="../assets/backTop.png">
                    </div>
                </BackTop>
            <!-- </div> -->
            
        </div>
</template>

<script>
import { Image, Row } from 'view-ui-plus';

    export default {
    components: { Image, Row },
    data() {
        return {
          headLogin: require("../assets/zbzt-log.png"),
          headBg: require("../assets/bg-01.png"),
          roll: 1,
          map: null,
        }
    },
    methods: {
        toLink(item) {
            this.$router.push({path: item.url});
            this.menuIdx = item.index
        },
        scrolling () {
            // 滚动条距文档顶部的距离
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            // 滚动条滚动的距离
            let scrollStep = scrollTop - this.oldScrollTop;
            // console.log("header 滚动距离 ", scrollTop);
            // 更新——滚动前，滚动条距文档顶部的距离
            this.oldScrollTop = scrollTop;

            //变量windowHeight是可视区的高度
            let windowHeight =
                document.documentElement.clientHeight || document.body.clientHeight;
            //变量scrollHeight是滚动条的总高度
            let scrollHeight =
                document.documentElement.scrollHeight || document.body.scrollHeight;

            //滚动条到底部的条件
            if (scrollTop + windowHeight == scrollHeight) {
                //你想做的事情
                // console.log("header  你已经到底部了");
            }
            if (scrollStep < 0) {
                // console.log("header 滚动条向上滚动了！");
            } else {
                // console.log("header  滚动条向下滚动了！");
                // if(scrollTop > 100 && this.roll === 0) {
                //     this.roll = 1
                // }
            }
            // 判断是否到了最顶部
            // if (scrollTop <= 100) {
            //     // console.log("header 到了最顶部")

            //     if(this.roll === 1) {
            //         this.roll = 0
            //     }
            // } 
    
        },
        openWechat () {
            window.open('https://work.weixin.qq.com/kfid/kfc47fa1af615683c07')
            return
        },
        openEnrollNow () {
            window.open('https://m.liangpinedu.cn/special/eventsgd/index.html?n=20210516jdyx#gwzxbmldy')
            return
        },
        toLink(item) {
            this.$router.push({path: item.url});
        }

    },
    mounted () {
        // window.addEventListener("scroll", this.scrolling);
        // this.scrolling();

        // this.selectQA(1)
        // this.mapInit();
        // this.mapConfig();
        window.document.title = '重本·紫腾 海外留学'
    },
    beforeDestroy() {
        // window.removeEventListener("scroll", this.scrolling);
    }
}
</script>

<style scoped>
.layoutA{
    background: #ffffff;
    overflow: hidden;
    width: 100%;
    height: 100%;
    font-weight: 400;
    max-width: 1920px;
    margin: 0 auto;
}


.backFast .bak{
    width: 4.5rem;
    height: 4.5rem;
    background: #fff;
    border-radius: 2px;
    box-shadow: rgb(136, 136, 136) 6px 6px 6px;
}

.backFast .bak img {
    width: 4.5rem;
    height: 4.5rem;
}

.customerSys:hover {
    content: url(../assets/customerSys-hover.png);
}

.customerSysWm:hover+.weixin_wm {
    display: block;
}

.followUs:hover {
    content: url(../assets/followUs-hover.png);
}

.followUs:hover+.erweima_wm {
    display: block;
}

.enrollNow:hover {
    content: url(../assets/enrollNow-hover.png);
}

.backTop:hover {
    content: url(../assets/backTop-hover.png);
}

.weixin_wm {
    position: fixed;
    right: 110px;
    display: none;
    margin-top: -72px;
    box-shadow: rgb(136, 136, 136) 6px 6px 12px;
    width: 200px;
    height: 200px;
}
.weixin_wm img{
    width: 200px;
    height: 200px;
}

.erweima_wm {
    position: fixed;
    right: 110px;
    display: none;
    -webkit-box-shadow: rgb(136, 136, 136) 6px 6px 12px;
    box-shadow: rgb(136, 136, 136) 6px 6px 12px;
    width: 200px;
    padding: 14px;
    text-align: center;
    top: 20%;
    background-color: #fff;
}
.erweima_wm img{
    width: 160px;
    height: 160px;
}

.bottom {
	width: 100%;
	height: 30px;
	font-size: 14px;
	line-height: 30px;
	color: #999;
	text-align: center;
	background-color: #4a4a4a;
}
</style>