
<template>
    <div class="layout">
        <Layout>
            <Header>
                <div id="header" :style="roll === 1 ? 'color: #000; background-color: #FFF; box-shadow: 6px 6px 6px #888888;' : 'color: #fff; background-color: transparent; box-shadow: none;'">
                    <Row class="ivu-text-center" :gutter="24" style="background: none; padding: 0;">
                        <Col flex="3">
                        </Col>    
                        <Col flex="5">
                            <Image :src="headLogin" width="24rem" height="5.25rem" :style="roll === 1 ? 'filter: none;' : 'filter: grayscale(100%) brightness(500%);'" />
                        </Col>
                        <Col flex="16" style="margin-left: 7.425rem;">
                            <Row>
                                <Col flex="12">
                                </Col>    
                                <Col flex="12" style="height:4.6rem;">
                                    <div class="tel" :style="roll === 1 ? 'color: #641f77;' : 'color: #fff;'">
                                       <div class="tel-tip">联系电话</div>
                                       <div class="tel-phone">020-8944 8086</div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col flex="2" v-for="(item, i) in menu" :key="i" :style="roll === 1 ? 'color: #000;' : 'color: #fff;'">
                                    <a :href="item.url" :style="roll === 1 ? 'color: #000;' : 'color: #fff;'">
                                        <span class="homeClumn" :style="menuIdx === item.index ? 'color: #641f77;' : ''">{{ item.name }}</span>
                                    </a>
                                </Col> 
                                <Col flex="5"></Col>  
                                <!-- <Menu mode="horizontal" theme="dark" active-name="1" >
                                    <MenuItem :name="item.index" v-for="(item, i) in menu" :key="i" :style="roll === 1 ? 'color: #000;' : 'color: #fff;'">
                                        <Icon :type="item.icon"></Icon>
                                        {{ item.name }}
                                    </MenuItem>
                                </Menu> -->
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Header>

            

            <Content class="content-teachTeam" v-if="roll === 1">
                <div style="width:100rem;height:45.75rem;margin: 2.25rem auto; background-color: #fff;">
                    <Row style="padding-top: 2.1875rem;">
                        <Col flex="3.2"></Col>
                        <Col flex="20.8">
                            <Breadcrumb>
                                <BreadcrumbItem to="/">首页</BreadcrumbItem>
                                <BreadcrumbItem to="/albumLst">学校相册</BreadcrumbItem>
                                <BreadcrumbItem >{{name}}</BreadcrumbItem>
                            </Breadcrumb>
                        </Col>
                    </Row>   
                    <!-- <Row>
                        <Col flex="3.2">
                        </Col>
                        <Col flex="18">
                            <img src="../assets/teacherTeam.png" style="margin-top: 35px;"/>
                        </Col>
                        <Col flex="2.8"></Col>
                    </Row>  -->
                    <Row>
                        <Col flex="3.2">
                        </Col>
                        <Col flex="24">
                            <div class="teacherInfo">
                                <div class="avatar"><img :src="albumImg"/></div>
                                <div class="teacher">
                                    <div class="teacher-name">{{name}}</div>
                                    <div class="teacher-introduction">{{introduction}}</div>
                                    <!-- <div class="teacher-center">{{content}}</div> -->
                                </div>
                            </div>
                        </Col>
                        <Col flex="0.8"></Col>
                    </Row> 
                    <Row style="height: 10.75rem; margin-top: 1.6rem; padding-top: 1.875rem; background-color: #9D9389;">
                        <Col flex="3.5">
                            <div style="color: rgb(255, 255, 255); margin: 1.875rem auto; margin-left: 5rem;">
                                <span style="font-size: 1.75rem;">{{ albumIdx < 9 ? '0' + (albumIdx + 1) : (albumIdx + 1)}}</span>
                                <span style="font-size: 1.25rem;"> / {{ albumSize }}</span>
                            </div>
                        </Col>
                        <Col flex="18" >
                            
                            <swiper  class="mySwiperB"
                                ref="mySwiperB"
                                :slides-per-view="5"
                                :space-between="50"
                                :spaceBetween="50"
                                @swiper="onSwiper"
                                @slideChange="onSlideChange"
                            >
                                <template v-for="(item, i) in albumLst" :key="i">
                                    <swiper-slide style="max-width:11.75rem; width:11.75rem; height: 6.75rem;">
                                        <a href="javascript:void(0);">
                                            <img :src="item.imageUrl" @click="slideImage(item, i)" :style="albumId === i ? 'border: 2px solid red;' : 'border:none;'"/>
                                        </a>
                                    </swiper-slide>
                                </template>
                            </swiper>

                        </Col>
                        <Col flex="3.5">
                            <a href="javascript:void(0);"><div @click="slidePrev($refs.mySwiperB)" class="swiper-button-prev-b"></div></a>
                            <a href="javascript:void(0);"><div @click="slideNext($refs.mySwiperB)" class="swiper-button-next-b"></div></a>
                        </Col>
                    </Row> 
                </div>
            </Content>

            <div class="about">
                <div class="main df">
                    <div class="left df">
                    <img src="../assets/img/bt-log.png"
                        class="bt-logo" alt="重本紫腾复读">
                    <div class="about-option">
                        <div class="key-word">
                        重本紫腾
                        </div>
                        <div class="option"><a href="../../static/introduce.htm">创办历史</a></div>
                        <div class="option"
                            @click="go(1)">品牌介绍</div>
                        <div class="option"
                            @click="go(2)">创始人介绍</div>
                        <div class="option"
                            @click="go(3)">办学特色</div>
                    </div>
                    <div class="about-option" style="padding-top:30px;">
                        <!-- <div class="key-word">
                        <div class="point"></div>联系我们
                    </div> -->
                        <div class="option"><a href="https://www.zhongbenziteng.com/gf-pc/index.html">高考复读</a></div>
                        <div class="option"><a href="https://www.zhongbenziteng.com/zf-pc/index.html">中考复读</a></div>
                        <div class="option"><a href="https://www.zhongbenziteng.com/yt-pc/index.html">艺体生文化课培训</a></div>
                        <div class="option"><a href="https://www.zhongbenziteng.com/zt-pc/pcgaozhi.html">高职高考</a></div>
                    </div>
                    </div>
                    <div class="qrcode">
                    
                        <div class="qr-option">
                            <img src="../assets/zbzt-douyin.png"
                                alt="中高复读_重本紫腾抖音号"
                                class="qr" style="border: 4px solid #fff;">
                            <div class="word">抖音号</div>
                        </div>
                        <div class="qr-option">
                            <img src="../assets/zbzt-vlog.png"
                                alt="中高复读_视频_重本紫腾视频号"
                                class="qr">
                            <div class="word">视频号</div>
                        </div>
                        <div class="qr-option">
                            <img src="../assets/zbzt-wechat.jpg"
                                alt="中高复读_了解更多_重本紫腾公众号"
                                class="qr">
                            <div class="word">公众号</div>
                        </div>
                    
                    </div>
                </div>
                </div>
            <div class="bottom">
            <b>© 广州市越秀区紫腾教育培训中心有限公司 版权所有。</b>
            <a class="beian"
                href="https://beian.miit.gov.cn"  rel="nofollow">粤ICP备2021074338号</a>
            <!-- | 本公司荣获SGS的ISO9001:2008认证 |
            增值电信业务经营许可证：粤B2-20170231 Copyright (C) 2002-2018 |
            <a class="beian" href="https://gdca.miit.gov.cn/">广东省通信管理局</a> -->
            </div>

            <div class="backFast" v-if="roll === 1">
                <div class="ivu-back-top ivu-back-top-show" data-v-47323bf2="" style="bottom: 500px; right: 30px;">
                    
                    <div class="customerSys bak customerSysWm" data-v-47323bf2="" @click="openWechat">
                        <img src="../assets/customerSys.png">
                    </div>
                    <div class="weixin_wm">
                        <img src="../assets/weixin_wm.png"/>
                    </div>
                </div>
                <div class="ivu-back-top ivu-back-top-show" data-v-47323bf2="" style="bottom: 420px; right: 30px;">
                    <div class="followUs bak" data-v-47323bf2="">
                        <img src="../assets/followUs.png">
                    </div>
                    <div class="erweima_wm">
                        <!-- <img src="../assets/erweima_wm.png"/> -->
                        <img src="../assets/zbzt-douyin.png"/>
                        抖音号
                        <img src="../assets/zbzt-vlog.png"/>
                        视频号
                        <img src="../assets/zbzt-wechat.jpg"/>
                        公众号
                    </div>
                    
                </div>
                <div class="ivu-back-top ivu-back-top-show" data-v-47323bf2="" style="bottom: 340px; right: 30px;">
                    <div class="enrollNow bak" data-v-47323bf2="" @click="openEnrollNow">
                        <img src="../assets/enrollNow.png">
                    </div>
                </div>
                <!-- <div class="ivu-back-top ivu-back-top-show" data-v-47323bf2="" style="bottom: 260px; right: 30px;"> -->
                    <BackTop :height="100" :bottom="260">
                        <div class="backTop bak" data-v-47323bf2="">
                            <img src="../assets/backTop.png">
                        </div>
                    </BackTop>
                <!-- </div> -->
            </div>
        </Layout>
    </div>
</template>

<script>
import { Image, Row } from 'view-ui-plus';
// Import Swiper Vue.js components
import { Swiper, SwiperSlide, useSwiper } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

// import required modules
import { Pagination, Navigation } from 'swiper';
import api from '../api/index'

export default {
    components: { Image, Row, Swiper, SwiperSlide, useSwiper},
    setup() {
        const swiper = useSwiper();

        return {
            swiper,
        };
    },
    data() {
        return {
          headLogin: require("../assets/zbzt-log.png"),
          headBg: require("../assets/bg-01.png"),
          roll: 1,
          name: '',
          introduction: '',
          teacherTeams: [],
          avatar: '',
          content: '',
          map: null,
          point: [113.299501,23.129081],
          albumId: '',
          zoom: '',
          loading: true,
          enrollNowModal: false,
          menuIdx: 5,
          menu: [
            {
                name: '首页',
                icon: 'ios-navigate',
                index: 1,
                url: "/"
            },
            {
                name: '最新动态',
                icon: 'ios-keypad',
                index: 2,
                url: "/noticeLst"
            },
            {
                name: '师资团队',
                icon: 'ios-paper',
                index: 4,
                url: "/teachTeam"
            },
            {
                name: '视频',
                icon: 'ios-paper',
                index: 7,
                url: "/videoLst"
            },
            {
                name: '相册',
                icon: 'ios-paper',
                index: 5,
                url: "/albumLst"
            },{
                name: '资料下载',
                icon: 'ios-paper',
                index: 6,
                url: "/dataDownload"
            },
            {
                name: 'Q&A',
                icon: 'ios-paper',
                index: 8,
                url: "/qaLst"
            }
          ],
          albumImg: '',
          albumId: '',
          albumLst: {},
          albumIdx: 0,
          albumSize: 0
        }
    },
    methods: {
        slideNext(node) {
            node.$el.swiper.slideNext();

            if ((this.albumIdx + 1) < this.albumSize) {
                this.albumIdx = this.albumIdx + 1
                this.slideImage(this.albumLst[this.albumIdx], this.albumIdx)
            }

        },
        slidePrev(node) {
            node.$el.swiper.slidePrev();

            if (this.albumIdx > 0) {
                this.albumIdx = this.albumIdx -1
                this.slideImage(this.albumLst[this.albumIdx], this.albumIdx)
            }
        },
        openWechat () {
            window.open('https://work.weixin.qq.com/kfid/kfc47fa1af615683c07')
            return
        },
        scrolling () {
            // 滚动条距文档顶部的距离
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            // 滚动条滚动的距离
            let scrollStep = scrollTop - this.oldScrollTop;
            // console.log("header 滚动距离 ", scrollTop);
            // 更新——滚动前，滚动条距文档顶部的距离
            this.oldScrollTop = scrollTop;

            //变量windowHeight是可视区的高度
            let windowHeight =
                document.documentElement.clientHeight || document.body.clientHeight;
            //变量scrollHeight是滚动条的总高度
            let scrollHeight =
                document.documentElement.scrollHeight || document.body.scrollHeight;

            //滚动条到底部的条件
            if (scrollTop + windowHeight == scrollHeight) {
                //你想做的事情
                // console.log("header  你已经到底部了");
            }
            if (scrollStep < 0) {
                // console.log("header 滚动条向上滚动了！");
            } else {
                // console.log("header  滚动条向下滚动了！");
                // if(scrollTop > 100 && this.roll === 0) {
                //     this.roll = 1
                // }
            }
            // 判断是否到了最顶部
            // if (scrollTop <= 100) {
            //     // console.log("header 到了最顶部")

            //     if(this.roll === 1) {
            //         this.roll = 0
            //     }
            // } 
    
        },
        toLink(item) {
            this.$router.push({path: item.url});
            this.menuIdx = item.index
        },
        slideImage (item, idx) {
            // this.name = item.name;
            // this.introduction = item.introduction;
            // this.content = item.content;
            this.albumId = idx;
            this.albumImg = item.imageUrl;

            if (item.title.length > 9){
                item.title = item.title.substring(0,9) + '..'
            }

            if (item.introduction.length > 200){
                item.introduction = item.introduction.substring(0,200) + '..'
            }

            this.name = item.title
            this.introduction = item.introduction

            this.albumIdx = idx
        },
        openEnrollNow () {
            window.open('https://m.liangpinedu.cn/special/eventsgd/index.html?n=20210516jdyx#gwzxbmldy')
            return
        },
        getAlbum() {
            let id = '';
            if (this.$route.query.id && this.$route.query.id !== undefined){
                id = this.$route.query.id
            }
            let that = this
            api.geAlbum({'id' : id}).then(function(rep){
                console.log(rep)
                if(rep.resultCode === 0) {
                    that.teacherTeams = rep.data

                    that.name = rep.data.title
                    that.introduction = rep.data.introduction
                    that.albumId = 0
                    that.albumLst = rep.data.contentImages
                    
                    that.slideImage(that.albumLst[0], 0)

                    that.albumIdx = 0
                    that.albumSize = that.albumLst.length
                }
            })
        },
    },
    mounted () {
        // window.addEventListener("scroll", this.scrolling);
        // this.scrolling();
        this.getAlbum();
        // this.mapInit();
        // this.mapConfig();
        
    },
    beforeDestroy() {
        // window.removeEventListener("scroll", this.scrolling);
    }
}
</script>

<style scoped>
.layout{
    background: #ffffff;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    font-weight: 400;
}

.layout .ivu-layout-header {
    position: fixed;
    z-index: 999;
    /* background: rgba(255,255,255,0.3); */
    height: 8rem;
    width: 100%;
    background: none; 
    padding: 0;
    top: 0;
}

.layout .ivu-image {
    line-height: 9rem;
}

.layout .ivu-text-center {
    height:8.5rem;
}

.ivu-menu-dark {
    background: none;
}

.ivu-menu-dark.ivu-menu-horizontal .ivu-menu-item, .ivu-menu-dark.ivu-menu-horizontal .ivu-menu-submenu {
    font-size: 1rem;
}

.ivu-menu-dark.ivu-menu-horizontal .ivu-menu-item:hover{
    color: #641f77;   
}

.layout-logo{
    width: 100px;
    height: 30px;
    background: #ffffff;
    border-radius: 3px;
    float: left;
    position: relative;
    top: 15px;
    left: 20px;
}
.layout-nav{
    /* width: 420px; */
    /* width:60%; */
    float:left;
    margin: 0 auto;
    margin-right: 20px;
}
.layout-footer-center{
    text-align: center;
    padding-top: 60px;
}

.layout-topMiddleBottom .ivu-menu-light{
    background:none;
}
.tel {
    font-size: 1.5rem;
    font-size: xx-large;
    margin-top: 0.9rem;
}
.tel .tel-tip {
    width: 3.5rem;
    font-size: 1.2rem;
    line-height: 1.5rem;
    float: left;
}

.tel .tel-phone {
    float: left;
    margin-top: -0.5rem;
}

.header-bg {
    position: relative;
}

.ivu-col{
    z-index: 999;
}

.backFast .bak{
    width: 4.5rem;
    height: 4.5rem;
    background: #fff;
    border-radius: 2px;
    box-shadow: rgb(136, 136, 136) 6px 6px 6px;
}

.backFast .bak img {
    width: 4.5rem;
    height: 4.5rem;
}

.customerSys:hover {
    content: url(../assets/customerSys-hover.png);
}

.customerSysWm:hover+.weixin_wm {
    display: block;
}

.followUs:hover {
    content: url(../assets/followUs-hover.png);
}

.followUs:hover+.erweima_wm {
    display: block;
}

.enrollNow:hover {
    content: url(../assets/enrollNow-hover.png);
}

.backTop:hover {
    content: url(../assets/backTop-hover.png);
}

.weixin_wm {
    position: fixed;
    right: 6.875rem;
    display: none;
    margin-top: -4.5rem;
    box-shadow: rgb(136, 136, 136) 0.375rem 0.375rem 0.75rem;
    width: 12.5rem;
    height: 12.5rem;
}
.weixin_wm img{
    width: 200px;
    height: 200px;
}

.erweima_wm {
    position: fixed;
    right: 110px;
    display: none;
    -webkit-box-shadow: rgb(136, 136, 136) 6px 6px 12px;
    box-shadow: rgb(136, 136, 136) 6px 6px 12px;
    width: 200px;
    padding: 14px;
    text-align: center;
    top: 20%;
    background-color: #fff;
}
.erweima_wm img{
    width: 160px;
    height: 160px;
}

.vertical-center-modal{
    display: flex;
    align-items: center;
    justify-content: center;
}
.vertical-center-modal .ivu-modal{
    top: 0;
}

.content-teachTeam {
    position: relative;
    z-index: 1;
    padding-top: 2rem;
    margin-top: 9rem;
    background-color: #EEEAE7;
    height:58.25rem;
    width: 100%;
    /* background-image: url('~@/assets/teacher-bg.png'); 
    background-repeat: round;
    background-size: cover; */
}

.teachTeamLst {
    height:370px; 
    width:200px; 
    margin-top: 20px; 
    margin-left: 70px; 
    float: left;
}

.teachTeamLst img {
    width: 100%; 
    height: 100%; 
    border-radius: 20px; 
    box-shadow: rgb(136, 136, 136) 6px 6px 12px;
}

.teachTeamTip {
    margin-top: -56px;
    opacity: 0.9;
    background-color: #000000f5;
    position: relative;
    z-index: 8;
    width: 100%;
    height: 50px;
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
}

.teachTeamLst p {
    margin-top: -25px;
    z-index: 22;
    position: relative;
    text-align: center;
    font-size: 20px;
    color: #fff;
    line-height: 0px;
    letter-spacing: 2px;
}

.about {
    width: 100%;
    height: 258px;
    background: #F9F9F9;
 }
  
.about .left {
  width: 450px;
}

.left .bt-logo {
	width: 186px;
	height: 162px;
}
  

.about-option .key-word {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}

.key-word .point {
	width: 6px;
	height: 6px;
	margin-right: 3px;
	border-radius: 50%;
	background-color: #000;
	overflow: hidden;
  }
	
.about-option .option {
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}

.about-option .option span:nth-child(2n) {
	margin-left: 20px;
}

.about-option .option a {
	font-size: 12px;
	font-weight: 400;
	color: #333333;
	text-decoration: none;
}


.about-option .strong {
  font-weight: bold;
}

.about .qrcode {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  height: 125px;  
}

.about .qrcode .qr-option {
	width: 103px;
	height: 100%;
}

.qr-option .qr {
  width: 103px;
  height: 103px;
}

.qr-option .word {
  margin-top: 6px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
  
.bottom {
	width: 100%;
	height: 30px;
	font-size: 14px;
	line-height: 30px;
	color: #999;
	text-align: center;
	background-color: #4a4a4a;
}

.df {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main {
  width: 975px;
  height: 100%;
  margin: 0 auto;
}

.webChain-lst {
    background-color: #f9f5fb;
    border-radius: 15px;
    height: 80px;
    margin-top: -35px;
}

.webchain {
    font-size: 25px;
    font-weight: 400;
    width: 22%;
    line-height: 65px;
    height: 100%;
}

.webchain span{
    color: #641f77;
}

.webchain span:hover{
    border-bottom: 1px solid #641f77;
}


.mySwiperB {
    height: 6.75rem;
    margin: 0 auto;
    height: 200px;
    width: 77.75rem;
}

.mySwiperB img{
    width:11.75rem; 
    height: 6.75rem;
    box-shadow: rgb(136, 136, 136) 0px 10px 8px;
    position: static;
}

.mySwiperB img:hover{
    border: 1px solid red;
}

.teacherInfo {
    padding: 45px;
    height: 500px;
}

.teacher {
    width: 65%;
    float: left;
    height: 100%;
}

.teacher-name {
    height: 20%;
    text-align: left;
    line-height: 65px;
    font-size: 30px;
    font-weight: bold;
    color: #000;
}

.teacher-introduction {
    height: 25%;
    color: #000;
    font-size: 21px;
}

.teacher-center {
    height: 30%;
    font-size: 20px;
}

.swiper-slide {
    max-width:11.75rem; 
    width:11.75rem; 
    height: 6.75rem;
}

.swiper .swiper-button-prev:after {
    content: url(../assets/left.png);
}

.swiper .swiper-button-next:after {
    content: url(../assets/right.png);
}

.swiper .swiper-button-next {
    position: absolute;
    z-index: 99;
    right: 17%;
}

.swiper-button-prev-b {
    background-image: url('~@/assets/left.png'); 
    background-repeat: round;
    width: 4.125rem;
    height: 4.125rem;
    float: left;
    margin-top: 2.25rem;
    margin-left: 1.75rem;
}

.swiper-button-next-b {
    background-image: url('~@/assets/right.png'); 
    background-repeat: round;
    width: 4.125rem;
    height: 4.125rem;
    float: left;
    margin-top: 2.25rem;
}

.avatar {
    width: 50%;
    float: left;
    height: 100%;
    margin-right: 5%;
    /* box-shadow: rgb(136, 136, 136) 8px 8px 10px; */
}

.avatar img {
    /* width: 70%; */
    margin: 0 auto;
    display: block;
    height: 100%;
    box-shadow: rgb(136, 136, 136) 8px 8px 10px;
}

.teacher {
    width: 43%;
    float: left;
    height: 100%;
}

.homeClumn:hover {
    color: #641f77;
    background-image: url('../assets/selected.png');
    background-repeat: repeat-y 100px;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 20px;
    background-size: 60px 60px;
}

.homeClumn {
    font-size: 17px;
    font-weight: bold;
    height: 60px;
    display: block;
    text-align: center;
}
.ivu-breadcrumb {
    font-size: 18px;
}
</style>