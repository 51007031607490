import axios from './axios'
import qs from 'qs'

export default {
    // getClassStudentLst (config) {
    //   return axios.get(`/api-ftRepastMenu/getClassStudentLst?${qs.stringify(config)}`)
    // },
    getNewestNotice (config) {
        return axios.get(`/zbzt/actNotice/getNewestNotice.do?${qs.stringify(config)}`)
    },
    getNoticePage (config) {
        return axios.get(`/zbzt/actNotice/getNoticePage.do?${qs.stringify(config)}`)
    },
    getTopFaculrtTeam (config) {
        return axios.get(`/zbzt/facultyTeam/getTopFaculrtTeam.do?${qs.stringify(config)}`)
    },
    getFaculrtTeamLst (config) {
        return axios.get(`/zbzt/facultyTeam/getFaculrtTeamLst.do?${qs.stringify(config)}`)
    },
    getLatestAlbum (config) {
        return axios.get(`/zbzt/studentAlbum/getLatestAlbum.do?${qs.stringify(config)}`)
    },
    getAlbumPage (config) {
        return axios.get(`/zbzt/studentAlbum/getAlbumPage.do?${qs.stringify(config)}`)
    },
    geAlbum (config) {
        return axios.get(`/zbzt/studentAlbum/geAlbum.do?${qs.stringify(config)}`)
    },
    getRecommendVideo (config) {
        return axios.get(`/zbzt/publicizeVideo/getRecommendVideo.do?${qs.stringify(config)}`)
    },
    getActTagLst (config) {
        return axios.get(`/zbzt/actNotice/tagLst.do`)
    },
    addActClick (config) {
        return axios.get(`/zbzt/actNotice/click.do?${qs.stringify(config)}`)
    },
    getArticle (config) {
        return axios.get(`/zbzt/actNotice/article/`+config.id+`.do`)
    },
    getActRecommendNotice (config) {
        return axios.get(`/zbzt/actNotice/getRecommendNotice.do`)
    },
    getDownloadFileLst (config) {
        return axios.get(`/zbzt/dataDl/fileLst.do?${qs.stringify(config)}`)
    },
    getQaLst (config) {
        return axios.get(`/zbzt/studentQa/getStudentQaLst.do?${qs.stringify(config)}`)
    },
    getQaTopLst (config) {
        return axios.get(`/zbzt/studentQa/getQaTopLst.do?${qs.stringify(config)}`)
    }
}
