import { createApp } from 'vue'
import ViewUIPlus from 'view-ui-plus'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import metaInfo from 'vue-meta-info'

moment.locale('zh-cn')

// import axios from "axios";
// import './style.scss'
import './styles/index.less'
// import './mock'

const app = createApp(App)

app.use(store)
  .use(metaInfo)
  .use(router)
  .use(moment)
  .use(ViewUIPlus)
  // .use(axios)
  .mount('#app')
  // .mounted(function() {
  //     document.dispatchEvent(new Event('render-event'));
  //   })
