
<template>
    <div class="layout">
        <Layout>
            <Header>
                <div id="header" :style="roll === 1 ? 'color: #000; background-color: #FFF; box-shadow: 6px 6px 6px #888888;' : 'color: #fff; background-color: transparent; box-shadow: none;'">
                    <Row class="ivu-text-center" :gutter="24" style="background: none; padding: 0;">
                        <Col flex="3">
                        </Col>    
                        <Col flex="5">
                            <Image :src="headLogin" width="24rem" height="5.25rem" :style="roll === 1 ? 'filter: none;' : 'filter: grayscale(100%) brightness(500%);'" />
                        </Col>
                        <Col flex="16" style="margin-left: 7.425rem;">
                            <Row>
                                <Col flex="12">
                                </Col>    
                                <Col flex="12" style="height:4.6rem;">
                                    <div class="tel" :style="roll === 1 ? 'color: #641f77;' : 'color: #fff;'">
                                       <div class="tel-tip">联系电话</div>
                                       <div class="tel-phone">020-8944 8086</div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col flex="2" v-for="(item, i) in menu" :key="i" :style="roll === 1 ? 'color: #000;' : 'color: #fff;'">
                                    <a :href="item.url" :style="roll === 1 ? 'color: #000;' : 'color: #fff;'">
                                        <span class="homeClumn" :style="menuIdx === item.index ? 'color: #641f77;' : ''">{{ item.name }}</span>
                                    </a>
                                </Col> 
                                <Col flex="5"></Col>  
                                <!-- <Menu mode="horizontal" theme="dark" active-name="1" >
                                    <MenuItem :name="item.index" v-for="(item, i) in menu" :key="i" :style="roll === 1 ? 'color: #000;' : 'color: #fff;'">
                                        <Icon :type="item.icon"></Icon>
                                        {{ item.name }}
                                    </MenuItem>
                                </Menu> -->
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Header>

            

            <Content class="content-notice" v-if="roll === 1">
                <Row>
                    <Col flex="4"></Col>
                    <Col flex="20">
                        <Breadcrumb>
                            <BreadcrumbItem to="/">首页</BreadcrumbItem>
                            <BreadcrumbItem to="/noticeLst">最新动态</BreadcrumbItem>
                            <BreadcrumbItem>文章详情</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </Row>   
            </Content>

            <div class="go-page">
                <!-- 活动公告 -->
                <div class="main page-main activity-notice">
                    <div style="max-width: 1920px;margin: 20px auto;font-size: 16px;font-weight: 300;margin-left: 17%;height: 200px; float: left; width: 100%;">
                                <p data-v-c381c054="" style="width: 100%;">
                                    <span data-v-c381c054="" style="font-size: 30px; color: black; float: none;">
                                        {{title}}
                                    </span>
                                </p>
                                <p data-v-c381c054="" style="margin-top: 20px;width: 100%;color:#7c8388;">
                        <span data-v-c381c054="" style="font-size: 14px;width: 150px;line-height: 25px;" v-if="createTime !== 'Invalid date'">
                                        {{createTime}}
                                    </span>
                                    <span style="float: none;width: 200px;">
                                            来源：重本紫腾
                                    </span>
                                    
                                </p>
                            </div>
                    <div class="notice-list" style="width: 100%;" v-if="innerHtmlStr === ''"></div>
                    <div class="notice-list" v-if="innerHtmlStr !== ''">
                        <div class="banner" v-if="swiperList != null && swiperList.length > 0">
                        <swiper :options="swiperOption"
                                class="swiper">
                            <swiper-slide v-for="(item,index) in swiperList"
                                        :key="index"
                                        >
                            <!-- <a :href="item.link"> -->
                                <img :src="item.url" class="banner-img" :alt="title"/>
                            <!-- </a> -->
                            </swiper-slide>
                        </swiper>
                        <!-- <div class="swiper-pagination"></div> -->
                        <!-- <img src="../assets/img/banner.png"
                            class="banner-img"> -->
                        </div>
                        <div v-html="innerHtmlStr" style="padding: 10px;"></div>
                    </div>

                    <div class="searchTap">
                        <div class="search bar4">
                            <form>
                                <input type="text" v-model="searchTxt" maxlength="20" placeholder="请输入您要搜索的内容...">
                                <button type="submit" @click="toSearchArtice"></button>
                            </form>
                            <p style='line-height: 25px;width: 63%;' v-if="tagLst.length > 0" class="searchTip">
                                <span style="width: 45px;">热搜：</span>
                                <a href="javascript:void(0)" @click="toTagArtice(item)" v-for="(item, i) in tagLst" style="width: auto;padding-right: 10px;">
                                    {{item.tag}}
                                </a>
                            </p>      
                        </div>

                        <div class="recommendNotic">
                            <p style="border-bottom: 1px solid #e4e3e3;width: auto;">热门公告</p>
                            <div style="margin-top: 10px;">
                            <ul>
                                <li v-for="(item, i) in activityNoticeData"
                                :key="i">
                                    <span :style="[{background: (i < 3 ? '#e65656' : '#afb2b7')}]">{{i+1}}</span>
                                    <a href="javascript:void(0)" @click="toArtice(item)" v-if="item.isOuterChain === 0 || item.url.indexOf('www.zhongbenziteng.com') != -1" >
                                    {{item.title}}
                                    </a>
                                    <a @click="toNofollow(item)" rel="nofollow" v-if="item.isOuterChain === 1 && item.url.indexOf('www.zhongbenziteng.com') == -1" >
                                    {{item.title}}
                                    </a>
                                </li>
                            </ul>
                            </div>
                        </div>
                        </div>
                    
                </div>
                </div>

            <div class="about">
                <div class="main df">
                    <div class="left df">
                    <img src="../assets/img/bt-log.png"
                        class="bt-logo" alt="重本紫腾复读">
                    <div class="about-option">
                        <div class="key-word">
                        重本紫腾
                        </div>
                        <div class="option"><a href="../../static/introduce.htm">创办历史</a></div>
                        <div class="option"
                            @click="go(1)">品牌介绍</div>
                        <div class="option"
                            @click="go(2)">创始人介绍</div>
                        <div class="option"
                            @click="go(3)">办学特色</div>
                    </div>
                    <div class="about-option" style="padding-top:30px;">
                        <!-- <div class="key-word">
                        <div class="point"></div>联系我们
                    </div> -->
                        <div class="option"><a href="https://www.zhongbenziteng.com/gf-pc/index.html">高考复读</a></div>
                        <div class="option"><a href="https://www.zhongbenziteng.com/zf-pc/index.html">中考复读</a></div>
                        <div class="option"><a href="https://www.zhongbenziteng.com/yt-pc/index.html">艺体生文化课培训</a></div>
                        <div class="option"><a href="https://www.zhongbenziteng.com/zt-pc/pcgaozhi.html">高职高考</a></div>
                    </div>
                    </div>
                    <div class="qrcode">
                    
                        <div class="qr-option">
                            <img src="../assets/zbzt-douyin.png"
                                alt="中高复读_重本紫腾抖音号"
                                class="qr" style="border: 4px solid #fff;">
                            <div class="word">抖音号</div>
                        </div>
                        <div class="qr-option">
                            <img src="../assets/zbzt-vlog.png"
                                alt="中高复读_视频_重本紫腾视频号"
                                class="qr">
                            <div class="word">视频号</div>
                        </div>
                        <div class="qr-option">
                            <img src="../assets/zbzt-wechat.jpg"
                                alt="中高复读_了解更多_重本紫腾公众号"
                                class="qr">
                            <div class="word">公众号</div>
                        </div>
                    
                    </div>
                </div>
                </div>
            <div class="bottom">
            <b>© 广州市越秀区紫腾教育培训中心有限公司 版权所有。</b>
            <a class="beian"
                href="https://beian.miit.gov.cn"  rel="nofollow">粤ICP备2021074338号</a>
            <!-- | 本公司荣获SGS的ISO9001:2008认证 |
            增值电信业务经营许可证：粤B2-20170231 Copyright (C) 2002-2018 |
            <a class="beian" href="https://gdca.miit.gov.cn/">广东省通信管理局</a> -->
            </div>

            <div class="backFast" v-if="roll === 1">
                <div class="ivu-back-top ivu-back-top-show" data-v-47323bf2="" style="bottom: 500px; right: 30px;">
                    
                    <div class="customerSys bak customerSysWm" data-v-47323bf2="">
                        <img src="../assets/customerSys.png">
                    </div>
                    <div class="weixin_wm">
                        <img src="../assets/weixin_wm.png"/>
                    </div>
                </div>
                <div class="ivu-back-top ivu-back-top-show" data-v-47323bf2="" style="bottom: 420px; right: 30px;">
                    <div class="followUs bak" data-v-47323bf2="">
                        <img src="../assets/followUs.png">
                    </div>
                    <div class="erweima_wm">
                        <!-- <img src="../assets/erweima_wm.png"/> -->
                        <img src="../assets/zbzt-douyin.png"/>
                        抖音号
                        <img src="../assets/zbzt-vlog.png"/>
                        视频号
                        <img src="../assets/zbzt-wechat.jpg"/>
                        公众号
                    </div>
                    
                </div>
                <div class="ivu-back-top ivu-back-top-show" data-v-47323bf2="" style="bottom: 340px; right: 30px;">
                    <div class="enrollNow bak" data-v-47323bf2="" @click="openEnrollNow">
                        <img src="../assets/enrollNow.png">
                    </div>
                </div>
                <!-- <div class="ivu-back-top ivu-back-top-show" data-v-47323bf2="" style="bottom: 260px; right: 30px;"> -->
                    <BackTop :height="100" :bottom="260">
                        <div class="backTop bak" data-v-47323bf2="">
                            <img src="../assets/backTop.png">
                        </div>
                    </BackTop>
                <!-- </div> -->
            </div>
        </Layout>
    </div>
</template>

<script>             
import api from '../api/index'
import moment from 'moment'
import Base64 from '../../public/static/js/base64'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/vue';
import 'swiper/css';
import { Image, Row } from 'view-ui-plus';

    export default {
    components: { Image, Row, Swiper, SwiperSlide, useSwiper},
    setup() {
        const swiper = useSwiper();

        return {
            swiper,
        };
    },
    metaInfo () {
        return {
        title: this.title,
        mate: [
            {
            name: 'keyWords',
            content: this.keywords + ',重本紫腾复读,中复，高复，艺体生，高职'
            },
            {
            name: 'description',
            content: this.description
            }
        ]
        }
    },
    data() {
        return {
          actColumn: [
            {
                'type': 1,
                'name': '热门话题'
            },
            {
                'type': 2,
                'name': '政策解读'
            },
            {
                'type': 3,
                'name': '校内动态'
            },
            {
                'type': 4,
                'name': '学生成长'
            }
          ],
          headLogin: require("../assets/zbzt-log.png"),
          headBg: require("../assets/bg-01.png"),
          roll: 1,
          map: null,
          pageIdx: 1,
          point: [113.299501,23.129081],
          zoom: '',
          loading: true,
          enrollNowModal: false,
          actColumnType: 1,
          menu: [
            {
                name: '首页',
                icon: 'ios-navigate',
                index: 1,
                url: "/"
            },
            {
                name: '最新动态',
                icon: 'ios-keypad',
                index: 2,
                url: "/noticeLst"
            },
            {
                name: '师资团队',
                icon: 'ios-paper',
                index: 4,
                url: "/teachTeam"
            },
            {
                name: '视频',
                icon: 'ios-paper',
                index: 7,
                url: "/videoLst"
            },
            {
                name: '资料下载',
                icon: 'ios-paper',
                index: 6,
                url: "/dataDownload"
            },
            {
                name: '相册',
                icon: 'ios-paper',
                index: 5,
                url: "/albumLst"
            },
            {
                name: 'Q&A',
                icon: 'ios-paper',
                index: 8,
                url: "/qaLst"
            }
          ],
          all: 1, //总页数
          cur: 1,//当前页码
          totalPage: 0,//当前条数
          pageSize: 6, // 页大小
          activityNoticeData: [],  // 活动公告列表
          isNotNotice: 0,  // 是否存在公告数据
          actId:'',
          tagLst: [],
          innerHtmlStr: '',
          title: '',
          createTime: '',
          keywords: '',
          swiperList: [],
          description: '',
          searchTxt: '',
          menuIdx: 2
        }
    },
    methods: {
      toTagArtice (item) {
        if (item && item.tag) {
            this.$router.push({ path: '/noticeLst', query: { tag: new Base64().encode(item.tag) } })
        }
      },
      toArtice (item) {
        if (item && item.url) {
            this.addClick(item.id.toString())
            if (item.url.indexOf('www.zhongbenziteng.com') != -1) {
                if (item.isOuterChain === 0) {
                    this.$router.push('/article/'+item.id)
                }else{
                    window.location.href = item.url
                }
            }else{
                window.location.href = item.url
            }
        }
      },
      toNofollow (item) {
        this.addClick(item.id.toString())
        window.location.href = item.url;
      },
      getActTagLst() {
        var that = this;
        api.getActTagLst().then(function (response) {
            console.log(response)
            if(response.resultCode === 0) {
                let tagAry = response.data.split(',')

                var size = tagAry.length > 3 ? 3 : tagAry.length
                for (var i=0; i < size; i++) {
                    var tagObj = {}
                    tagObj.tag = tagAry[i]
                    that.tagLst.push(tagObj)
                }
            }
        })
        .catch(function (error) {
            console.log(error);
        });
      },  
      getArticle() {
        var that = this;

        api.getArticle({
            'id': that.actId
        }).then(function (response) {
            if(response.resultCode === 0) {
                if (response.data !== null) {
                    that.swiperList = JSON.parse(response.data.banner)
                    that.title = response.data.title

                    if (response.data.createTime !== null) {
                        that.createTime = moment(response.data.gmtCreate).format("yyyy-MM-DD HH:mm:ss")
                    }
                     
                    if (response.data.content !== null) {
                        that.innerHtmlStr = new Base64().decode(response.data.content)
                    }

                    if (that.innerHtmlStr && that.innerHtmlStr.length > 0){
                        var reg = new RegExp("<img", "gm")
                        that.innerHtmlStr = that.innerHtmlStr.replace(reg, "<img style='max-width:100%;' alt='"+that.title+"' ")
                    }
                    that.keywords = response.data.keywords
                    that.description = response.data.description
                }
            }
        })
        .catch(function (error) {
            console.log(error);
        });
      }, 
      getRecommendNotice () {
        var that = this;
        that.isNotNotice = 0
        api.getActRecommendNotice()
            .then(function (response) {
            if (response.data !== null) {
                that.activityNoticeData = response.data

                for (var i = 0; i < that.activityNoticeData.length; i++) {
                    if (that.activityNoticeData[i].title.length > 18){
                        that.activityNoticeData[i].title = that.activityNoticeData[i].title.substring(0,18) + '..'
                    }
                }
            }
            })
            .catch(function (error) {
            console.log(error);
            });
      },
      addClick (click) {
            var that = this;
            api.addActClick({
                'click' : new Base64().encode(click) ,
                'secret': new Base64().encode('asDD54@@#hdjk' + moment(new Date()).format("yyyy-MM-DD"))
            }).then(function (response) {})
                .catch(function (error) {
                // console.log(error.resultMessage);
            });
        },  
      toArticle(item) {
        if (item && item.url) {
            this.addClick(item.id.toString())
            if (item.url.indexOf('www.zhongbenziteng.com') != -1) {
                if (item.isOuterChain === 0) {
                    this.$router.push('/article/'+item.id)
                }else{
                    window.location.href = item.url
                }
            }else{
                window.location.href = item.url
            }
        }
      },
      changePage: function(event) {
        this.dataListFn(event)
      },  
      dataListFn: function(index) {
        var that = this;
        that.isNotNotice = 0
        var tag = ''
        if (this.$route.query.tag && this.$route.query.tag !== undefined){
            tag = this.$route.query.tag
        }
        api.getNoticePage({
            page: index,
            rows: that.pageSize,
            tag: tag,
            columnType: that.actColumnType
        })
            .then(function (response) {
                that.activityNoticeData = []
                if (response.resultCode === 0 && response.rows !== null) {
                    that.activityNoticeData = response.rows
                    
                    for (var i = 0; i < that.activityNoticeData.length; i++) {
                        that.activityNoticeData[i].gmtCreate = moment(that.activityNoticeData[i].gmtCreate).format("yyyy年MM月DD日")

                        if (that.activityNoticeData[i].title.length > 9){
                            that.activityNoticeData[i].title = that.activityNoticeData[i].title.substring(0,9) + '..'
                        }

                        if (that.activityNoticeData[i].description.length > 40){
                            that.activityNoticeData[i].description = that.activityNoticeData[i].description.substring(0,40) + '..'
                        }
                    }

                    that.all = response.total; //总页数
                    that.cur = index;
                    that.totalPage = response.records;
                }
                console.log(response)
                })
            .catch(function (error) {
                console.log(error.resultMessage);
            });
        },
        prevPage: function() {
            if(this.cur > 1) {
                this.cur = this.cur - 1 
                this.dataListFn(this.cur);

            }
        },
        nextPage: function() {
            if((this.cur + 1) <= this.all) {
                this.cur = this.cur + 1 
                this.dataListFn(this.cur);
            }
        },
        toLink(item) {
            this.$router.push({path: item.url});
            this.menuIdx = item.index
        },
        scrolling () {
            // 滚动条距文档顶部的距离
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            // 滚动条滚动的距离
            let scrollStep = scrollTop - this.oldScrollTop;
            // console.log("header 滚动距离 ", scrollTop);
            // 更新——滚动前，滚动条距文档顶部的距离
            this.oldScrollTop = scrollTop;

            //变量windowHeight是可视区的高度
            let windowHeight =
                document.documentElement.clientHeight || document.body.clientHeight;
            //变量scrollHeight是滚动条的总高度
            let scrollHeight =
                document.documentElement.scrollHeight || document.body.scrollHeight;

            //滚动条到底部的条件
            if (scrollTop + windowHeight == scrollHeight) {
                //你想做的事情
                // console.log("header  你已经到底部了");
            }
            if (scrollStep < 0) {
                // console.log("header 滚动条向上滚动了！");
            } else {
                // console.log("header  滚动条向下滚动了！");
                // if(scrollTop > 100 && this.roll === 0) {
                //     this.roll = 1
                // }
            }
            // 判断是否到了最顶部
            // if (scrollTop <= 100) {
            //     // console.log("header 到了最顶部")

            //     if(this.roll === 1) {
            //         this.roll = 0
            //     }
            // } 
    
        },
        openEnrollNow () {
            window.open('https://m.liangpinedu.cn/special/eventsgd/index.html?n=20210516jdyx#gwzxbmldy')
            return
        },
        actColumnTo(index) {
            this.actColumnType = index
            this.dataListFn(1)
        },
        toSearchArtice () {
            if (this.searchTxt && this.searchTxt.length > 0) {
                this.$router.push({ path: '/noticeLst', query: { tag: new Base64().encode(this.searchTxt) } })
            }
        },
    },
    mounted () {
        this.actId = this.$route.params.id;
        window.addEventListener("scroll", this.scrolling);
        this.scrolling();
        this.getArticle();
        this.getRecommendNotice();
        this.getActTagLst();
        // this.dataListFn(1);
        // this.mapInit();
        // this.mapConfig();
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.scrolling);
    }
}
</script>

<style scoped>
.layout{
    background: #ffffff;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    font-family: PingFang SC-Medium;
    font-weight: 400;
}

.layout .ivu-layout-header {
    position: fixed;
    z-index: 999;
    /* background: rgba(255,255,255,0.3); */
    height: 8rem;
    width: 100%;
    background: none; 
    padding: 0;
    top: 0;
}

.layout .ivu-image {
    line-height: 9rem;
}

.layout .ivu-text-center {
    height:8.5rem;
}

.ivu-menu-dark {
    background: none;
}

.ivu-menu-dark.ivu-menu-horizontal .ivu-menu-item, .ivu-menu-dark.ivu-menu-horizontal .ivu-menu-submenu {
    font-size: 1rem;
}

.ivu-menu-dark.ivu-menu-horizontal .ivu-menu-item:hover{
    color: #641f77;   
}

.layout-logo{
    width: 100px;
    height: 30px;
    background: #ffffff;
    border-radius: 3px;
    float: left;
    position: relative;
    top: 15px;
    left: 20px;
}
.layout-nav{
    /* width: 420px; */
    /* width:60%; */
    float:left;
    margin: 0 auto;
    margin-right: 20px;
}
.layout-footer-center{
    text-align: center;
    padding-top: 60px;
}

.layout-topMiddleBottom .ivu-menu-light{
    background:none;
}
.tel {
    font-size: 1.5rem;
    font-size: xx-large;
    margin-top: 0.9rem;
}
.tel .tel-tip {
    width: 3.5rem;
    font-size: 1.2rem;
    line-height: 1.5rem;
    float: left;
}

.tel .tel-phone {
    float: left;
    margin-top: -0.5rem;
}

.header-bg {
    position: relative;
}

.ivu-col{
    z-index: 999;
}

.content-video {
    position: relative;
    height: 35rem;
    z-index: 1;
    background-color: #fff;
    padding-top: 2rem;
}

.content-video .video-card {
    width: 20rem;
    border-radius: 2rem;
    height: 31rem;
    float: right;
}

.content-video .content-card {
    width: 20rem;
    border-radius: 2rem;
    height: 15rem;
    float: left;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.backFast .bak{
    width: 4.5rem;
    height: 4.5rem;
    background: #fff;
    border-radius: 2px;
    box-shadow: rgb(136, 136, 136) 6px 6px 6px;
}

.backFast .bak img {
    width: 4.5rem;
    height: 4.5rem;
}

.customerSys:hover {
    content: url(../assets/customerSys-hover.png);
}

.customerSysWm:hover+.weixin_wm {
    display: block;
}

.followUs:hover {
    content: url(../assets/followUs-hover.png);
}

.followUs:hover+.erweima_wm {
    display: block;
}

.enrollNow:hover {
    content: url(../assets/enrollNow-hover.png);
}

.backTop:hover {
    content: url(../assets/backTop-hover.png);
}

.weixin_wm {
    position: fixed;
    right: 6.875rem;
    display: none;
    margin-top: -4.5rem;
    box-shadow: rgb(136, 136, 136) 0.375rem 0.375rem 0.75rem;
    width: 12.5rem;
    height: 12.5rem;
}
.weixin_wm img{
    width: 200px;
    height: 200px;
}

.erweima_wm {
    position: fixed;
    right: 110px;
    display: none;
    -webkit-box-shadow: rgb(136, 136, 136) 6px 6px 12px;
    box-shadow: rgb(136, 136, 136) 6px 6px 12px;
    width: 200px;
    padding: 14px;
    text-align: center;
    top: 20%;
    background-color: #fff;
}
.erweima_wm img{
    width: 160px;
    height: 160px;
}

.vertical-center-modal{
    display: flex;
    align-items: center;
    justify-content: center;
}
.vertical-center-modal .ivu-modal{
    top: 0;
}

.content-notice {
    position: relative;
    z-index: 1;
    padding-top: 12rem;
    background-color: #fff;
}

.notice-lst {
    height: 350px;
    padding: 30px;
}

.notice-banner {
    padding-left: 50px; 
    background-image: url('~@/assets/notice-bakimg.png'); 
    background-repeat: no-repeat;
    padding: 10px 0 0 10px;
    margin-left: 30px;
}

.notice-date {
    border-right: 1px solid #9E9E9E;
    padding: 5px;
    line-height: 25px;
    text-align: center;
    margin-right: 10px;
}

.ivu-list-item-meta-title {
    font-size: 25px;
    margin-bottom: 15px;
}

.ivu-list-item-meta-description {
    font-size: 18px;
    color: #66707b;
}

.notice-more {
    background-color: #eae6e6;
    border: 1px solid #eae6e6;
    width: 90;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 17px;
    border-radius: 5px;
}

.notice-cul {
    font-size: 18px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #fff;
    width: 90;
    height: 40px;
    text-align: center;
    line-height: 40px;
    margin-right: 2rem;
}


.notice-cul span:hover{
    border-bottom: 1px solid #000;
}

.notice-cul span{
    color: #515a6e;
}

.cul-checked {
    box-shadow: rgb(136, 136, 136) 1px 1px 5px;
}

.cul-checked span {
    color: #641f77;
}

.content-aboutUs {
    position: relative;
    height: 75rem;
    background-color: #fcfcfc;
    z-index: 1;
    padding-top: 2rem;
}

.about-brand {
    font-size: 18px;
    font-weight: 400;
    color: #000;
    line-height: 24px;
    text-indent: 2em;
    padding: 30px;
    letter-spacing: 2px;
}

.about-founder {
    font-size: 18px;
    font-weight: 400;
    color: #000;
    line-height: 24px;
    text-indent: 2em;
    padding: 30px;
    letter-spacing: 2px;
}

.content-teachingCh {
    position: relative;
    z-index: 1;
    padding-top: 2rem;
    background-color: #f5f5f5;
}

.content-teachTeam {
    position: relative;
    z-index: 1;
    padding-top: 3rem;
    background-color: #fff;
    height: 40rem;
}

.teachTeamLst {
    height:370px; 
    width:200px; 
    margin-top: 20px; 
    margin-left: 70px; 
    float: left;
}

.teachTeamLst img {
    width: 100%; 
    height: 100%; 
    border-radius: 20px; 
    box-shadow: rgb(136, 136, 136) 6px 6px 12px;
}

.teachTeamTip {
    margin-top: -56px;
    opacity: 0.9;
    background-color: #000000f5;
    position: relative;
    z-index: 8;
    width: 100%;
    height: 50px;
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
}

.teachTeamLst p {
    margin-top: -25px;
    z-index: 22;
    position: relative;
    text-align: center;
    font-size: 20px;
    color: #fff;
    line-height: 0px;
    letter-spacing: 2px;
}

.content-album {
    position: relative;
    z-index: 1;
    background-color: #fff;
    height: 54rem;
    background-image: url('~@/assets/album-backImg.png'); 
    background-repeat: no-repeat;
}

.schoolAlbum {
    height: 500px;
    margin-top: 60px;
}

.content-qa {
    position: relative;
    z-index: 1;
    background-color: #fff;
    padding-bottom: 70px;
}

.ivu-collapse {
    background-color: #ffffff;
    border: none;
}

.ivu-collapse-item {
    margin-bottom: 15px;
    /* border: 1px solid #dcdcdc; */
    background-color: #f3f3f3;
    border-radius: 20px;
    /* line-height: 45px; */
    padding: 15px;
}

.ivu-collapse-item .ivu-tag-size-large{
    margin-right: 30px;
    width: 100px;
}

.ivu-collapse-content {
    background-color: #f3f3f3;
}

.ivu-collapse-content-boxTip img{
    width: 30px;
    height: 30px;
}

.ivu-collapse-content-boxTip span{
    margin-left: 38px;
    margin-top: -45px;
    display: block;
    font-size: 16px;
    letter-spacing: 1px;
}

.footer-webChain {
    background-color: #fff;
    z-index: 1;
}


.about {
    width: 100%;
    height: 258px;
    background: #F9F9F9;
 }
  
.about .left {
  width: 450px;
}

.left .bt-logo {
	width: 186px;
	height: 162px;
}
  

.about-option .key-word {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}

.key-word .point {
	width: 6px;
	height: 6px;
	margin-right: 3px;
	border-radius: 50%;
	background-color: #000;
	overflow: hidden;
  }
	
.about-option .option {
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}

.about-option .option span:nth-child(2n) {
	margin-left: 20px;
}

.about-option .option a {
	font-size: 12px;
	font-weight: 400;
	color: #333333;
	text-decoration: none;
}


.about-option .strong {
  font-weight: bold;
}

.about .qrcode {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  height: 125px;  
}

.about .qrcode .qr-option {
	width: 103px;
	height: 100%;
}

.qr-option .qr {
  width: 103px;
  height: 103px;
}

.qr-option .word {
  margin-top: 6px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
  
.bottom {
	width: 100%;
	height: 30px;
	font-size: 14px;
	line-height: 30px;
	color: #999;
	text-align: center;
	background-color: #4a4a4a;
}

.df {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main {
  width: 975px;
  height: 100%;
  margin: 0 auto;
}

.webChain-lst {
    background-color: #f9f5fb;
    border-radius: 15px;
    height: 80px;
    margin-top: -35px;
}

.webchain {
    font-size: 25px;
    font-weight: 400;
    width: 22%;
    line-height: 65px;
    height: 100%;
}

.webchain span{
    color: #641f77;
}

.webchain span:hover{
    border-bottom: 1px solid #641f77;
}


.notice-tab {
    width:100%; 
    background-color:#FFF;
}

.notice-tabLst {
    height: 80px;
    line-height: 60px;
    width: 90%;
    margin: 0 auto;
    padding-top: 15px;
}

.notice-lst {
    height: 600px;
    width: 90%;
    margin: 0 auto;
    padding: 40px;
    background-color: #fcfcfc;
    border-radius: 15px;
    margin-top: 20px;
}

.notice-lst ul{
    width:100%;
}

.notice-lst ul li{
    width: 47%;
    float: left;
    border-radius: 10px;
    list-style: none;
    background-color: #f5f5f5;
    padding: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
}

.notice-lst-img {
    width:30%; float: left;
}

.notice-lst-img img{
    width:120px; height:120px;
}

.notice-lst-content {
    width: 65%;
    float: left;
    margin-left: 5%; 
    text-align: left;
}

.notice-lst-title {
    width: 100%;
    font-size: 22px;
    color: #000;
}

.notice-lst-title span{
    font-size: 14px;
    color: #949090;
    position: relative;
    float: right;
    display: block;
    line-height: 33px;
}

.notice-lst-description {
    width:100%;     
    margin-top: 20px;
}

.noticeLst-page {
    height: 80px;
    text-align: center;
    margin-top: 30px;
}

.go-page {
    width: 100%;
    background: #fff;
    padding-top: 20px;
    float: left;
    padding-bottom: 60px;
}
.page-main {
    display: inline;
}

.main {
  width: 975px;
  height: 100%;
  margin: 0 auto;
}
.df {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.none {
  /* display: none; */
  position: absolute;
  left: 0;
  width: 100%;
  height: 0;
}

.activity-notice {
      color: #333333;
}

.activity-notice a {
    color: #333333;
    width: 487px;
    display: block;
}
     
.activity-notice a:hover {
    color: #278ff0;
}

.activity-notice .title {
position: relative;
text-align: center;
font-family: PingFangSC-Semibold,PingFang SC;
font-weight: 600;
color: #333;
}
.activity-notice .notice-tip {
margin-top: 0px auto;
font-size: 20px;
background-color: #FFF;
width: 100%;
left: -285px;
}

.activity-notice input::-webkit-inner-spin-button{ 
    -webkit-appearance: none !important;    
    margin: 0;    
    -moz-appearance: textfield; 
}

.activity-notice p {
        font-weight: 500;
        margin: 0px auto;
        width: 450px;
        color: #333333;
}

.activity-notice p span {
    width: 140px;
    float: left;
}
.activity-notice p  a {
    width: 80px;
    float: left;
}
.activity-notice p input {
    width: 20px;
    margin-left: 2px;
    margin-right: 2px;
    border: 1px solid #b9b4b4;
    text-align: center;
}

.activity-notice .notice-list {
    max-width: 820px;
    margin: 10px auto;
    margin-left: 17%;
    float: left;
}

.banner {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
}

.banner .swiper{
    width: 100%;
}
.banner .banner-img {
    width: 95%;
    max-width: 820px;
    max-height: 200px;
}
    
.banner .swiper-pagination{
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}

.my-bullet {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background-color: #0c27c5;
  border-radius: 50%;
  opacity: .4;
}

.my-bullet-active {
  background: #fff;
  opacity: 1;
}

/*分页*/
.page-bar{
  margin: 0px auto;
  height: 60px;
  background-color: #FFF;
  width: 100%;
}
.page-bar ul,li{
  margin: 0px;
  padding: 0px;
}

.page-bar ul {
  width: 600px;
  margin: 0 auto;
}
.page-bar li{
  list-style: no ne
}
.page-bar li:first-child>a {
  margin-left: 0px
}
.page-bar a{
  border: 1px solid #ddd;
  text-decoration: none;
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #5D6062;
  cursor: pointer;
  margin-right: 20px;
  -webkit-user-select:none;/*谷歌 /Chrome*/
	-moz-user-select:none; /*火狐/Firefox*/
	-ms-user-select:none;    /*IE 10+*/
	user-select:none;
}
.page-bar a:hover{
  background-color: #eee;
}
.page-bar a.banclick{
  cursor:not-allowed;
}
.page-bar .active a{
  color: #fff;
  cursor: default;
  background-color: #E96463;
  border-color: #E96463;
}
.page-bar i{
  font-style:normal;
  color: #d44950;
  margin: 0px 4px;
  font-size: 12px;
}

.notice-list img {
  width: 100%;
}

/*搜索框4*/

#container {
  width: 500px;
  height: 820px;
  margin: 0 auto;
}
div.search {padding: 30px 0;    margin-bottom: 30px;}

form {
  position: relative;
  width: 300px;
  margin: 0 auto;
}

input, button {
  border: none;
  outline: none;
}

input {
  width: 100%;
  height: 42px;
  padding-left: 18px;
}

button {
  height: 42px;
  width: 42px;
  cursor: pointer;
  position: absolute;
}

.bar4 form {
    background: #f6f7f9;
    /* border-bottom: 2px solid #BE290E; */
}
.bar4 input, .bar4 button {
    background: transparent;
    border: 1px solid #dcdfe8;
}
.bar4 button {
    top: 0;
    right: -20px;
    height: 44px;
    background-image: url('../assets/img/search.png');
    background-size: 100% 100%;
    border: 1px solid #dcdfe8;
    background-color: white;
}

.searchTap{
  width: 25%;
  top: 30%;
  float: left;
  right: 6%;
}

.recommendNotic {
  margin-left: 16%;
  background-color: #f6f7f9;
  width: 76%;
  padding: 20px;
  line-height: 30px;
}

ul {
  width: auto;
}

ul li{
  line-height: 30px;
  list-style: none;
}

ul li span {
  color: #ffffff;
  /* font-weight: 600; */
  background: #afb2b7;
  margin-right: 10px;
  width: 20px;
  display: block;
  float: left;
  text-align: center;
  height: 20px;
  line-height: 20px;
}

ul li a {
  position: relative;
  top: -5px;
}

ul li:nth-child(n+3):span {
  background: #f80511;
}
.searchTip span{
  float: left;
}
.searchTip a{
  float: left;
  width: 60px;
}

.homeClumn:hover {
    color: #641f77;
    background-image: url('../assets/selected.png');
    background-repeat: repeat-y 100px;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 20px;
    background-size: 60px 60px;
}

.homeClumn {
    font-size: 17px;
    font-weight: bold;
    height: 60px;
    display: block;
    text-align: center;
}

.ivu-breadcrumb {
    font-size: 17px;
}
</style>