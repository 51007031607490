<style scoped>
.layout{
    border: 1px solid #d7dde4;
    background: #f5f7f9;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}
.layout-logo{
    width: 100px;
    height: 30px;
    background: #5b6270;
    border-radius: 3px;
    float: left;
    position: relative;
    top: 15px;
    left: 20px;
}
.layout-nav{
    width: 420px;
    margin: 0 auto;
    margin-right: 20px;
}
.layout-footer-center{
    text-align: center;
}
.layout .ivu-layout-header {
    background: #191a23;
}
.layout-topMiddleBottom .ivu-menu-light{
    background:none;
    color: #fff
}

.popContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
}

.pop-tab2 {
    margin: 0px auto;
    top: 20%;
    margin-top: 10%;
    text-align: center;
}

.pop-tab2 p {
    font-size: 1rem;
}

</style>
<template>
    <div class="layout">
        <Layout>
            <div class='popContainer'>
                <div class="pop-tab2">
                    <img src="../assets/mini-wechat.png" />
                    <p>长按保存图片, 微信扫一扫打开小程序</p>
                </div>

            </div>
        </Layout>
    </div>
</template>
<script>
   export default {
    data() {
        return {
            wxHtml:''
        }
    },
    methods: {
        // config() {
        //     let myUrl = window.location.href;
        //     this.$axios.get("/api/wx/open/jsdk/config", {
        //         params: {
        //             url: myUrl
        //         }
        //     }).then(data => {
        //         if (data.data) {
        //             wx.config({
        //                 debug: false,
        //                 appId: data.data.appId,
        //                 timestamp: data.data.timeSpan,
        //                 nonceStr: data.data.nonceStr,
        //                 signature: data.data.sinature,
        //                 jsApiList: ['openLocation'],
        //                 openTagList: ['wx-open-launch-weapp']
        //             });
        //             this.getHtml()
        //         }
        //     })
        // },
        // getHtml() {
        //     let script = document.createElement('script')
        //     script.type = 'text/wxtag-template'
        //     script.text = `<button class="btn blue mt1" style="height: 50px;width: 100px;">跳转小程序</button>`
        //     this.wxHtml =
        //         `<wx-open-launch-weapp style="height: 50px;width: 100px;"
        //                           id="launch-btn"
        //                             appid="xxxxx"
        //                             username="gh_xxxxxxxx"
        //                             path="pages/index/index.html"
        //                           >${script.outerHTML}
        //                         </wx-open-launch-weapp>`
        //     this.$nextTick(() => {
        //         let launchButton = document.getElementById('launch-btn')
        //         launchButton.addEventListener('launch', function(e) {
        //             console.log('success', e)
        //         })
 
        //         launchButton.addEventListener('error', function(e) {
        //             console.log('fail', e)
        //         })
        //     })
        // }
    },
    mounted() {
        // this.config()
    },
    
}
</script>