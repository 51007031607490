//对于axios进行二次封装
import axios from "axios";

//底下的代码也是创建axios实例
let requests = axios.create({
  //基础路径
  baseURL: process.env.VUE_APP_API_URL,
  //请求不能超过5S
  timeout: 20000,
});
 
//请求拦截器（可以在请求发出去之前，做一些事情）
requests.interceptors.request.use((config) => {
    //   return config;
    //这里我看别人用的不是config，也是用的request，属实不建议这么来。
    // if (process.env.NODE_ENV === 'production'){
    //     //这里的$project我在main入口文件将public文件下的设置的全局变量挂到实例上面去了
    //     config.baseURL = Vue.prototype.$project.mainBaseUrl + Vue.prototype.$project.mainProjectName
    // } 
    // console.log('config.baseUrl', config)
    // if (getLocalStorage('FileManager')) {   
    //     let data = getLocalStorage('FileManager')
    //     config.headers['token'] = data.token
    // }
        // config.data = "data=" + JSON.stringify(config.data)
    return config;
});
 
//响应拦截器（在数据返回之后，做一些事情）
requests.interceptors.response.use(
  (res) => {
    //相应成功
    return res.data;
  },
  (err) => {
    //响应失败
    // alert("服务器响应数据失败");
    console.log(err)
  }
);
export default requests;