import { createRouter, createWebHistory } from 'vue-router'
import index from '@/components/index.vue'
import noticeLst from '@/components/noticeLst.vue'
import teachTeam from '@/components/teachTeam.vue'
import dataDownload from '@/components/dataDownload.vue'
import videoLst from '@/components/videoLst.vue'
import qaLst from '@/components/qaLst.vue'
import albumLst from '@/components/albumLst.vue'
import album from '@/components/album.vue'
import article from '@/components/article.vue'
import overseas from '@/components/overseas.vue'
import miniWechat from '@/components/miniWechat.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/noticeLst',
    name: 'noticeLst',
    component: noticeLst
  },
  {
    path: '/teachTeam',
    name: 'teachTeam',
    component: teachTeam
  },
  {
    path: '/dataDownload',
    name: 'dataDownload',
    component: dataDownload
  },
  {
    path: '/videoLst',
    name: 'videoLst',
    component: videoLst
  },
  {
    path: '/qaLst',
    name: 'qaLst',
    component: qaLst
  },
  {
    path: '/albumLst',
    name: 'albumLst',
    component: albumLst
  },
  {
    path: '/album',
    name: 'album',
    component: album
  },
  {
    path: '/article/:id',
    name: 'article',
    component: article
  },
  {
    path: '/overseas',
    name: 'overseas',
    component: overseas
  },
  {
    path: '/miniWechat',
    name: 'miniWechat',
    component: miniWechat
  }
]

const router = createRouter({
  mode: 'history',
  base: '/',
  routes,
  history: createWebHistory('/'),
  scrollBehavior() {
    return { top: 0 }
  }
})

export default router
