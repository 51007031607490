<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
.body {
  background-color: #ffffff;
  font-family: 'SourceHanSansSC-Normal';
  font-weight: 400;
}
</style>